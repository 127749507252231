export default function TryNowCopy() {
  return {
    title: `Let's build your first application`,
    subtitle: `Only takes a few minutes to get started`,
    feedback: {
      title: 'Customer testimonials',
      customers: [
        {
          src: '/customers/logo-dropbox.png',
          feedback:
            'Especially compared to other vendors that Dropbox has worked with in the past, the Apryse engineering support was just very responsive.',
          company: 'Dropbox',
          name: 'Amanda Lansman',
        },
        {
          src: '/customers/logo-ava.png',
          feedback:
            'The support they gave us was consistently very helpful and highly responsive. We hardly had to rely on it due to the quality of the documentation. Apryse proved very easy for our developers to put into our existing codebase.',
          company: 'AVA Industries Ltd',
          name: 'Matthew Henschke',
        },
        {
          src: '/customers/logo-drawboard.png',
          feedback:
            'Apryse demonstrated superior speed and functionality out-of-the-box. In contrast, the competition took a lot of shortcuts, and it was obvious in the user experience.',
          company: 'Drawboard',
          name: 'Alistair Michener',
        },
      ],
    },
    platforms: {
      web: {
        name: 'Web App',
        icon: '/web.svg',
        items: {
          frameworks: [
            {
              text: 'React',
              link: '/documentation/web/get-started/react/',
              logo: '/logos/logo-react-color.svg',
            },
            {
              text: 'Angular',
              link: '/documentation/web/get-started/angular/new-project/',
              logo: '/logos/logo-angular-color.svg',
            },
            {
              text: 'Vue',
              link: '/documentation/web/get-started/vue/',
              logo: '/logos/logo-vue-color.svg',
            },
            {
              text: 'Next.js',
              link: '/documentation/web/get-started/nextjs/',
              logo: '/logos/logo-nextjs-color.svg',
            },
            {
              text: 'NuxtJS',
              link: '/documentation/web/get-started/nuxt/',
              logo: '/logos/logo-nuxt-color.svg',
            },
            {
              text: 'Svelte',
              link: '/documentation/web/get-started/svelte/',
              logo: '/logos/logo-svelte-color.svg',
            },
            {
              text: 'Electron',
              link: '/documentation/web/get-started/electron/',
              logo: '/logos/logo-electron-color.svg',
            },
            {
              text: 'Blazor',
              link: '/documentation/web/get-started/blazor/new-project/',
              logo: '/logos/logo-blazor-color.svg',
            },
          ],
          languages: [
            {
              text: 'JavaScript',
              link: '/documentation/web/get-started/npm/',
              logo: '/logos/logo-javascript-color.svg',
            },
            {
              text: 'TypeScript',
              link: '/documentation/web/get-started/typescript/',
              logo: '/logos/logo-typescript-color.svg',
            },
          ],
        },
      },
      mobile: {
        name: 'Mobile App',
        icon: '/mobile.svg',
        items: {
          native: [
            {
              text: 'Android',
              link: '/documentation/android/get-started/integration/gradle/',
              logo: '/logos/logo-android-color.svg',
            },
            {
              text: 'iOS',
              link: '/documentation/ios/get-started/integration/cocoapods/',
              logo: '/logos/logo-apple-color.svg',
            },
          ],
          frameworks: [
            {
              text: 'React Native',
              link: '/documentation/guides/react-native/',
              logo: '/logos/logo-react-color.svg',
            },
            {
              text: 'Xamarin',
              link: '/documentation/xamarin/',
              logo: '/logos/logo-xamarin-color.svg',
            },
            {
              text: 'Flutter',
              link: '/documentation/guides/flutter/',
              logo: '/logos/logo-flutter-color.svg',
            },
          ],
        },
      },
      server: {
        name: 'Server App',
        icon: '/server.svg',
        items: {
          frameworks: [
            {
              text: 'Node.js',
              link: '/documentation/guides/get-started/nodejs/',
              logo: '/logos/logo-nodejs-color.svg',
            },
            {
              text: '.NET',
              link: '/documentation/dotnet/get-started/',
              logo: '/logos/logo-dotnet-color.svg',
            },
            {
              text: '.NET Core',
              link: '/documentation/dotnetcore/get-started/',
              logo: '/logos/logo-dotNet-core-color.svg',
            },
          ],
          languages: [
            {
              text: 'Java',
              link: '/documentation/java/',
              logo: '/logos/logo-java-color.svg',
            },
            {
              text: 'C++',
              link: '/documentation/cpp',
              logo: '/logos/logo-cplus-color.svg',
            },
            {
              text: 'Python',
              link: '/documentation/python/',
              logo: '/logos/logo-python-color.svg',
            },
            {
              text: 'Go',
              link: '/documentation/go/',
              logo: '/logos/logo-go-color.svg',
            },
            {
              text: 'Ruby',
              link: '/documentation/ruby/',
              logo: '/logos/logo-ruby-color.svg',
            },
            {
              text: 'PHP',
              link: '/documentation/php/',
              logo: '/logos/logo-php-color.svg',
            },
          ],
        },
      },
      lowCode: {
        name: 'Integrations',
        icon: '/lowcode.svg',
        hideTitle: true,
        items: {
          frameworks: [
            {
              text: 'Salesforce',
              link: '/documentation/salesforce/',
              logo: '/logos/logo-salesforce-color.svg',
            },
            {
              text: 'Appian',
              link: '/documentation/appian/',
              logo: '/logos/logo-appian-color.svg',
            },
            {
              text: 'Mendix',
              link: '/documentation/mendix/',
              logo: '/logos/logo-mendix-color.svg',
            },
            {
              text: 'SharePoint',
              link: '/documentation/sharepoint/',
              logo: '/logos/logo-sharepoint-color.svg',
            },
            {
              text: 'OutSystems',
              link: '/documentation/outsystems/',
              logo: '/logos/logo-outsystems-color.svg',
            },
            {
              text: 'MS Office (Fluent)',
              link: 'https://apryse.com/form/fluent-trial',
              logo: '/logos/logo-fluent.svg',
            },
            {
              text: 'Microsoft Teams',
              link: '/documentation/web/get-started/microsoft-teams/',
              logo: '/logos/logo-ms-teams-color.svg',
            },
          ],
        },
      },
      preBuilt: {
        name: 'Pre-built +',
        icon: '/prebuilt.svg',
        hideTitle: true,
        items: {
          frameworks: [
            {
              text: 'Fluent',
              link: 'https://apryse.com/form/fluent-trial',
              logo: '/logos/logo-fluent.svg',
            },
            {
              text: 'PDF2Data',
              link: 'https://pdf2data.apryse.com/documentation/docs/Getting%20started',
              logo: '/logos/logo-pdf2data.svg',
            },
          
          ],
        },
      },
    },
  };
}
